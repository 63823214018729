import React, { useEffect } from 'react';
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import SimplePageLayout from '../../layouts/SimplePageLayout';
import {systemError} from '../../actions/SystemActions';
import { useDispatch } from 'react-redux';

/**
 * A virtual page to handle openid redirects
 * @returns 
 */
export const SignIn = () => {
  const isLoggedIn = useIsAuthenticated();
  const history = useHistory();
  const { inProgress, instance } = useMsal();
  const completed = isLoggedIn && inProgress == InteractionStatus.None;
  const { error } = useMsalAuthentication('none');
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      if (error.message.includes('access_denied: AADB2C90091: The user has cancelled entering self-asserted information')) {
        location.href = '/';
    return;
      }
      // Try to logout to clear local Msal cache
      instance.logoutRedirect({
        onRedirectNavigate: () => false,
      });

      // Redirect to global error handler
      dispatch(systemError({
        type: 'Login',
        message: error.errorMessage,
        page: '/error/login'
      }));      
    } else if (completed) {
      history.push('/');
    }
  }, [error, completed]);

  // Safari cannot redirect to Azure AD
  if (!isLoggedIn && inProgress === 'none') {
    // Let the user know they opened the app in the browser if they return to the wrapper
    const handleOpenBrowser = () => {
      history.replace('/error/browser');
    };

    return (
      <SimplePageLayout
        pageTitle="The ITM App"
        hasBackButton={false}
      >
      <p className="SystemError__description"><a href="/" target="_blank" rel="noreferrer" onClick={handleOpenBrowser}>Click here to continue</a></p>

      </SimplePageLayout>
    );
  }

  // While we're waiting for authentication to complete we show nothing
  return <></>;
};

SignIn.propTypes = {};

SignIn.defaultProps = {};
